/* Metronome styles */

.metronome {
  //max-width: 600px;
  margin: 0 auto;
  padding-top: .5em;
  background-color: $cypress; 
  font-family: $header-font-family;
  font-weight: 600;

  /* Title & Tempo styles*/
  h1, label, input {
    font-size: 1.8rem;
    /* medium up is 2.1rem */  
  }

  h1 {
    padding-left: 1rem;
    width: 50%;
    float: left;
    line-height: 1.5;
    margin-top: .125rem;
  }

  .tempo {
    float: right;
    input {
      position: relative;
      top: -.1rem;
      height: 100%;
      text-align: right;
      background: $cypress;
      float: right;
      border: none;
      box-shadow: none;
      margin-bottom: .1rem;
      font-weight: 500;
      &:hover, &:focus {
        background-color: $bone;
        width: 80%;
        border: none;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      } 
    }
  }

  label {
    font-weight: 500;
    color: $mahogany;
    padding-right: 1rem;
    padding-top: .125rem;
    float: right;
    line-height: 1.5;   
    /*medium up padding-top: 0;*/
  } 

  /* Clock container styles*/
  .bezel {
    position: relative;     //sets .metronome as a container block for .face  
    width: 280px;
    height: 280px;
    margin: 0 auto;
    top: 3em;
    @media only screen and (min-width: 30em) {  
      top: 1.5rem;
    } 
    @media only screen and (min-width: 60em) {  //switch over at a bit lower than the medium threshold
      width: 360px;
      height: 360px;
    } 
    /*medium up – top: 1.5em;*/
  }

  .face {     
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 300px;
    background: $bone;
  }

  /* Clock face and hands */
  .face > div:not(.hand) {            // applies style to all of the clock face numbers 
    font-family: $header-font-family;
    font-weight: 500;
    font-size: 1.5rem;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }

  .one {left: 73%; top: 11%;}

  .two {left: 89%; top: 28%;}

  .three {left: 95%; top: 50%;}

  .four {left: 89%; top: 72%;}

  .five {left: 73%; top: 89%;}

  .six {left: 50%; top: 95%;}

  .seven {left: 27%; top: 89%;}

  .eight {left: 11%; top: 72%;}

  .nine {left: 5%; top: 50%;}

  .ten {left: 12%; top: 28%;}

  .eleven {left: 28%; top: 11%;}

  .twelve {color: #B51A48; left: 50%; top: 5%;}

  .face::before {       // metronome center dot
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 18px;
    margin-left: -9px;
    margin-top: -9px;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
  }

  .face::after {
    content: "Ravenna Flamenco";
    position: absolute;
    width: 100%;
    font-family: $header-font-family;
    font-weight: 500;
    font-size: 1.2em;
    color: $cypress;
    text-align: center;
    top: 64%;
  }

  .hand {
    position: absolute; 
    width: 6px; /* default width of hand */
    height: 38%; /* default height of hand */
    top: 12%; /* offset hand vertically by 20% so its bottom is at center of clock */
    left: 50%; /* offset hand horiz by 50% so its left is at center of clock */
    margin-left: -3px; /* Account for hand width to center hand horizonally */
    background: black;
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -moz-transform-origin: bottom; /* Set transform origin to center bottom */
    -ms-transform-origin: bottom;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    z-index: 1;
  }

  &.alegria, &.solea {
    .hand {
      -moz-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
    }
    .one {color: #B51A48;}
    .twelve {color: $mahogany;}
  }

  &.seguiriya {
    .hand {
      -moz-transform: rotate(-120deg);
      -ms-transform: rotate(-120deg);
      -webkit-transform: rotate(-120deg);
      transform: rotate(-120deg);
    }
    .eight {color: #B51A48;}
    .twelve {color: $mahogany;} 
  }

  /* Four count */
  &.four-count {
    .face {
      background-color: $cypress;
      &::before, &::after {display: none;}
      & > div {
        font-size: 8em;
        font-family: 'Open Sans', sans-serif;
      }

    }
    .face::after {      
      display: hidden;

    }
    .one {left: 0%; top: 50%; color: #B51A48;}
    .two {left: 31%; top: 50%;}           /* sets spacing visually */
    .three {left: 66%; top: 50%;}
    .four {left: 100%; top: 50%;}
  }

  /* Three count */
  &.three-count {
    .bezel {
      top: 3em;
      @media only screen and (min-width: 30em) {  
        top: .75rem;
      } 
      @media only screen and (min-width: 60em) {  //switch over at a bit lower than the medium threshold
        width: 360px;
        height: 360px;
      } 
    }
    .face {
      background-color: rgba(0, 0, 0, 0);;
      &::before, &::after {display: none;}
      & > div {
        font-size: 8.5em;
        font-family: 'Open Sans', sans-serif;
      }

    }
    .face::after {      
      display: hidden;
    }
    .one {left: 12%; top: 50%; color: #B51A48;}
    .two {left: 50%; top: 50%;}
    .three {left: 90%; top: 50%;}
    .visible-labels {
      position: absolute;
      bottom: 15px;
      left: 33%;
      width: 250px;
      overflow: hidden;
      font-size: 24px;
      font-weight: 500;
      color: $mahogany;
      @media only screen and (min-width: 60em) {  
        bottom: 50px;
      } 
    }
    #section-labels {
      position: relative;
      width: 750px;
      right: 0;
      &.intro {right: 0;}
      &.salida {right: 145px;}
      &.first {right: 275px;}
      &.second {right: 403px;}
      &.third {right: 530px;}


      @media only screen and (min-width: 60em) {
        &.salida {right: 130px;}
        &.first {right: 260px;}
        &.second {right: 388px;}
        &.third {right: 515px;}
      }
      -webkit-transition: right .25s ease-in-out; /* Safari */
      transition: right .25s ease-in-out;
      span {
        display: inline-block;
        width: 125px;
        text-align: center;
        opacity: .15;
        -webkit-transition: opacity .5s ease-in-out; /* Safari */
        transition: opacity .5s ease-in-out;
        &.active {
          opacity: 1;
        }
      }
    }
  }


  /* Controls */
  .controls {
    margin: 4.5rem 1.2rem 0;
    @media only screen and (min-width: 30em) {  
      margin: 0 1.2rem;
    }   
    /*medium-up margin: 0 1.2rem;*/
  }

  button {
    width: 3rem;
    margin: 0 0 1rem;
    font-size: 0;
    outline: none;
    border: 1px solid $cypress;
    /*medium up width: 4rem;*/
    &.small {
    }


    svg {             // to be properly nested and isolated
      position: relative;
      top: .25em;
      min-width: 1em;
      max-height: 2em;    // keeps older versions of safari and ie9 from going crazy
      fill: $mahogany;
    }
  }

  .reset, .replay, .reset_4, .reset_4_disabled, .downTempo {
    margin-left: .25rem; 
    margin-right: .25rem;
  }
  .pause, .reset_4 {
    display: none;
  }

  .pauseDisabled:before {
    cursor: default;
    opacity: .5;
  }

  .replay {
    display: none;
  }

  .tempoControls {
    float:right;
  }
}