//  Ravenna Flamenco Custom Styles
//  ------------------------------
//  Table of Contents:
//
//  1. Global
//  2. Header & Nav
//  3. Shared Page Styles
//  4. Home Page 
//  5. Category Landing Page
//  6. Resource Page
//  7. Metronome Page 
//  8. Tag Index, 404 & Generic
//  9. Comment Block
// 10. Related Content
// 11. Footer

// 1. Global Styles
// ----------------
  body {
    border-top: $cedar 3px solid;
    margin: 0;
    background: scale-color($bone, $lightness: 90%);
  }
  h1 {
    margin-top: .8rem;
    line-height: 1;
    font-family: $header-sc-font-family;
  }
  p, h1 {
    a:link, a:visited, a:hover, a:active {
      color: $rosewood;
    }
  }
  .tags {
    font-family: 'Alegreya Sans SC', Roboto, Arial, sans-serif;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-left: 0;
    li {
      display: inline;
      margin-right: .3rem;
    }
  }

// 2. Header and Nav Styles
// ------------------------
  header.site {
    @include xy-grid-container();
    .nameplate {
      @include xy-grid();
      .logo {
        @include xy-cell();
        @include breakpoint(medium) {
          @include xy-cell(1);
          margin-top: .5rem;
        }
        @include breakpoint(large) {
          margin-top: .75rem;
        }    
        img {
          @include breakpoint(small only){
            display: block;
            margin: 1.5rem auto 1rem;
            width: 120px;
          }
          @include breakpoint(large) {
            position: relative;
            right: 5px; // if I want to move the logo out for large sizez.
          }
        }
      }
      .site-title {
        margin: 0 auto;
        @include breakpoint(medium) {
          @include xy-cell(7);
        }
        a, a:visited, a:hover, a:active, a:focus {
          color: $mahogany;
        }
        h1, h2 {
          font-family: $body-font-family;
          text-align: center;
          @include breakpoint(medium) {
            text-align: left;
          }
        }
        h1 {
          font-size: 38px;
          margin-top: .25rem;
          padding-bottom: .25rem;
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
          @include breakpoint(large) {
            font-size: 48px;
            margin-top: 1rem;
            margin-bottom: 0;
            padding-bottom: .25rem;
            letter-spacing: .33rem;
          }
        }  
        h2 {
          font-size: 18px;
          letter-spacing: .33rem;
          margin: .5rem auto .25rem;
          @include breakpoint(medium) {
            margin-top: 0;
          }
          @include breakpoint(large) {
            font-size: 20px;
            margin: 0;
            letter-spacing: .5rem;
          }    
        }
        hr {
          width: 95%;
          margin: -5px auto 0;
          border: solid $mahogany;
          border-width: 1px 0 0;
          clear: none;
          @include breakpoint(medium) {
            display: none;
          }
        } 
      }
      .social {
        display: none;
        a, a:visited, a:hover, a:active, a:focus {
          color: $mahogany;
        }
        @include breakpoint(medium) {
          @include xy-cell(4);
          display: block;
          text-align: right;
          font-size: 22px;
          padding: .5rem 1.5rem;
          li {
            display: inline-block;
            padding-left: .5rem;
          }
        }
        @include breakpoint(675) {
          li {
            padding-left: 1rem;
          }
        }
      }
    }
  }
  nav.global {
    background: $mahogany;
    margin: 1rem 0 0;
    .main-nav {
      @include xy-grid-container();
      @include breakpoint(small only) {
        padding: 0;
      }
    }
    ul {
      margin: 0;
      a, a:visited, a:hover, a:active, a:focus {
        color: $bone;
      }
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(11);
        @include xy-cell-offset(1);
      }
    }
    li {
      font-family: $header-sc-font-family;
      font-weight: medium;
      font-size: 1.5em;
      letter-spacing: .05em;
      padding-left: 12px;
      line-height: 56px;
      list-style: none;
      border-bottom: 1px solid $rosewood;
      span.fas {
        float: right;
        font-size: 1.2rem;
        padding-right: 12px;
        line-height: inherit;
      }
      @include breakpoint(medium) {
        display: inline-block;
        padding-right: 2.5rem;
        padding-left: 0;
        font-size: 1.33rem;
        .fas {
          display: none;
        }
        .active {
          border-bottom: 3px solid $cypress;
          padding-bottom: 12px;
        }
      }
      @include breakpoint(large) {
        padding-right: 5rem;
      }
    } 
  }
  nav.breadcrumb {
    @include xy-grid-container();
    margin-top: .5rem;
    div {
      @include xy-grid();
    }
    a:hover {
      text-decoration: none;
    }
    ul {
      @include xy-cell();
      margin-bottom: 0;
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(11);
        @include xy-cell-offset(1);
      }
    }
    li {
      display: inline-block;
      font-family: $header-font-family;
      font-weight: medium;
      opacity: .66;
      list-style: none;
    }
  }

// 3. Shared Page Styles
// ---------------------
  main {
    @include xy-grid-container();
  }
  ul.tags {
    margin-top: .5rem;
    margin-bottom: .125rem;
    color: $mahogany; 
    line-height: 1.25rem;
    &.pipe {
      font-weight: 500;
    }
    a:link, a:visited {
      color: $mahogany;
    }
    a:hover, a:active {
      color: $cedar;
    }
  }
  ul.commas li:not(:last-child):after {
    content: ",";
  }
  ul.pipe li:not(:last-child):after {
    content: "|";
    padding-left: .3333rem;
  }
  .social-comment-count {
    @include xy-cell();
    @include breakpoint(medium){
      @include xy-cell($gutters:25);
    }
    margin-top: 1rem;
    margin-bottom: 1rem; 
    .fb-box {
      display: none;
    }
    .fb-button {
      float: left;
      width: 133px;
      height: 24px;
    }
    .comment-count {
      float: left;
      border-radius: 5px;
      background-color: $mahogany;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 75%;
      font-weight: 400;
      padding: 5px 10px 3px;
      margin-left: 1rem;
      a {
        color: $bone;
      }
      span.fas {
        padding-left: 5px;
        font-size: 90%;
      }
    }
    @include breakpoint(large) {
      margin: 0;
      padding-left: .75rem;
      @include xy-cell(1);
      @include flex-order(-9);
      .fb-button {
        display: none;
      }
      .fb-box{
        display: inline-block;
        height: 70px;
        width: 51px;
      }
      .comment-count {
        float: none;
        margin-top: .75rem;
        margin-left: 0;
        border: 1px solid $mahogany;
        width: 51px;
        height: 32px;
        padding-top: 3px;
        position: relative;
        &:hover {
          border: 1px solid $cedar;
          background-color: $cedar;
        }
        span.fas, span.comment-label {
          display: none;
        }
        span.disqus-comment-count {
          display: inline-block;
          width: 100%;
          text-align: center;
          padding-top: 2px;
        }
        &:before {
          border-color: $mahogany transparent;
          border-style: solid;
          bottom: -7px;
          border-width: 6px 0 0 8px;
          content: "";
          display: block;
          left: 29px;
          position: absolute;
          width: 0;
        }
        &:hover:before {
          border-color: $cedar transparent;
        }
      }
    }
  }
  .byline{
    @include xy-cell();
    @include breakpoint(medium) {
      @include xy-cell($gutters:25);
    }
    @include breakpoint(large) {
      @include xy-cell($gutters:50);
    }
    font-family: $header-font-family;
    font-size: 16px;
    margin-bottom: 2rem;
    @include breakpoint(small only) {
      padding-top: 2rem;          
    }
    p {
      margin-bottom: 0;
      color: $rosewood;
    }
  }  
  .resource-page .byline {
    @include breakpoint(large) {
      @include xy-cell(11);
      @include xy-cell-offset(1);
    }
  }

// 4. Home Page Styles
// -------------------
  .homepage-banner {
    @include flex;
    @include flex-align($y:bottom);
    background-image: url(/assets/img/global/guitar-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 10%;
    background-position-y: 33%;
    @include breakpoint(medium){
      min-height: 400px;
    }
    .overlay {
      @include xy-cell($gutters:0);
      background-color: rgba(64, 30, 15, 0.8);
      @include breakpoint(medium){      
        background-color: rgba(64, 30, 15, 0.8);
      }
    }
    .tagline-container {
      @include xy-grid-container();
    }
    .tagline-wrap {
      @include xy-grid();
    }  
    h1 {
      @include xy-cell();
      color: $cypress;
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      line-height: 1.5;
      padding: 1rem 0;
      @include breakpoint(large){
        @include xy-cell(10, $gutters:0);
        @include xy-cell-offset(1);
        text-align: left;
        //margin-left: 12rem;
        //margin-right: 12rem;
        font-size: 40px;
        line-height: 1.33;
        //padding: 1.5rem 2rem 1.5rem .5rem;
      }  
    }
  }
  .home-page-features {  
    /*name-spacing for now for safety – unify these styles later*/
    margin-top: 1rem;
    @include breakpoint(large) {
      margin-top: 3rem;
    }
    p, h1 {
      a:link, a:visited, a:hover, a:active {
        color: $rosewood;
      }
    }
    h2 {
      line-height: 1.25;
      @include breakpoint(large) {
        line-height: 1.4;
      }
    }
    p, li {
      font-size: 16px;
    }
    .comment-count {
      font-family: $header-sc-font-family;
      color: $mahogany;
      font-size: 16px;
      span.far {
        font-size: .66rem;
        margin-right: .25rem;
      }  
    }
  // home page hero story --------------
    .explore {  
      padding-bottom: 3rem;
      .hero { 
        @include xy-grid();
        @include breakpoint(large) {
          @include xy-grid(vertical);
          height: 360px;
        }
      }         
      h1, figure, p, .comment-count {
        @include xy-cell();
        @include breakpoint(medium) {
          @include xy-cell($gutters:25);
        }  
        @include breakpoint(large) {
          @include xy-cell(6, $gutters:50);        
        }
      }
      h1 {
        font-size: 32px;
        margin-bottom: .5rem;
        @include breakpoint(large) {
          font-size: 38px;
          line-height: 1.25;
          padding-bottom: .25rem;
        }
      }
      figure {
        padding-bottom: 1rem;      
        @include breakpoint(large) {            
          @include flex-order(-1);
          height: 100%; /* keeps image in own column */
        } 
      }
      p {
        @include breakpoint(medium) {
          font-size: 19px;
          margin-bottom: 1rem;
        }
      }
       
    } 
  // home page features and tiles ------
    .articles {
      @include xy-grid();
      @include breakpoint(medium) {
        //@include xy-gutters(10, $negative:true);
      }
      @include breakpoint(large) {
        @include xy-grid-layout(2, '.feature', $gutters:50);
        //@include xy-gutters(50, $negative:true);
      }  
    }
    h1.category-heading {
      text-transform: none;
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }  
      @include breakpoint(large) {
        @include xy-cell($gutters:50);
      }  
      font-size: 21px;
      font-weight: 500;
      a:link, a:visited, a:active  {
        color: $mahogany;
      }
      span {
        float: right;
        font-size: 21px;
        &.fas {
          font-size: 12px;
          margin-top: .33rem;
          padding-left: .33rem;
        }
      }
    }
  // home page metronome tiles ------
    .metronomes, .tab {
      @include xy-grid();
      @include breakpoint(medium) {
        @include xy-grid-layout(3, '.tile', $gutters:25);
        //@include xy-gutters(10, $negative:true)
      } 
      @include breakpoint(large) {
        @include xy-grid-layout(3, '.tile', $gutters:50);
        //@include xy-gutters(50, $negative:true)
      } 
    }
    article.tile {
      @include xy-cell();
      margin-bottom: 3rem;
      @include breakpoint(large) {
        //@include xy-gutters(25);
        margin-bottom: 4rem;
      }
    }
    article.feature {
      margin-bottom: 3rem;
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell(6, $gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(6, $gutters:50);
        margin-bottom: 4rem;
      }
    }
    .feature p {
      @include breakpoint(large) {
        font-size: 19px;
      }
    }  
  }

// 5. Category Landing Page Styles
// -------------------------------
  article.list-index {
    @include xy-grid();
    header {
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-gutters(18);
        @include flex-order(-10);
      }
      @include breakpoint(large) {
        @include xy-cell(11);
        @include xy-cell-offset(1);
        @include flex-order(-10);
      }
    }
    figure {
      @include xy-cell();
      @include xy-gutters(10,$negative:true);
      width: 100vw;
      img {
        width: 100%;
      }
      @include breakpoint(large) {
        @include xy-cell(7);
        @include flex-order(-8);
      }
      figcaption {
        text-align: right;
        margin-top: -.3rem;
        padding-right: 10px;
      }
    } 
    section.body {
      @include xy-cell();
      margin-bottom: 1rem;
      @include breakpoint(medium){
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        font-size: 19px;
        @include xy-cell(7,$gutters:50);
        @include xy-cell-offset(1);
      }
    }
    section.ad-block {
      @include xy-cell();
      margin-bottom: 1rem;
      @include breakpoint(medium){
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(4);
        @include flex-order(-7);
        text-align: center;
        img {
          display: inline-block;
        }
      }
    }
    section.article-list {
      @include xy-cell();
      @include breakpoint(medium){
        @include xy-cell($gutters:0);
      }
      @include breakpoint(large) {
        @include xy-cell(8, $gutters:0);
        padding-top: 2rem;
      }
      .tile {
        @include xy-grid();
        font-size: .8889rem;
        margin-bottom: 2rem;          
        h2, h3.tab {
          font-family: $header-font-family;
          font-size: 1.5rem;
          margin-bottom: .25rem;
          @include breakpoint(large) {
            margin: 0;
            line-height: 1.25;
          }
        }
        @include breakpoint(medium) {
          @include xy-grid(vertical);
          height: 275px;
        }
        @include breakpoint(large) {
          @include xy-grid(vertical);
          height: 250px;
        }
        h2, h3.tab, ul, p, div {
          @include xy-cell($gutters:0);
          @include breakpoint(medium) {
            @include xy-cell(6, $gutters:25);         
          }
          @include breakpoint(large) {
            @include xy-cell(7, $gutters:50);
            margin-left: 0;
          }
        }
        .hero {        
          @include breakpoint(medium) {
            @include xy-cell(6, $gutters:25);
            @include flex-order(-1);
            height: 100%; /* keeps image in own column */
          } 
          @include breakpoint(large) {
            @include xy-cell(6, $gutters:50);
          }
        }
        p {
          margin-bottom: .5rem;
          font-size: 17px;
          @include breakpoint(medium) {
            font-size: 16px;
          }
        }
        .comment-count {
          font-family: $header-font-family;
          color: $mahogany;
          span.far {
            font-size: .66rem;
            margin-right: .25rem;
          }  
        }
      }
    }
    .tab-categories {
      @include xy-grid();
      @include breakpoint(medium) {
        @include xy-gutters(12.5);
      }
      @include breakpoint(large) {
        @include xy-gutters(42.5);
      }
    }
    h2.tab-category {
      @include xy-cell();
      margin-top: .5rem;
      margin-bottom: 1rem;
      font-family: $header-sc-font-family;
      text-transform: capitalize;
      border-bottom: 1px solid $mahogany; 
      @include breakpoint(small only) {
        margin-left: 0;
        width: 100%;
      }
    }
    section.article-grid {
      @include xy-cell();
      @include breakpoint(large) {        
        @include xy-cell(8);
      }
      padding-bottom: 2rem;
      .grid {
        @include xy-grid();
        @include breakpoint(medium) {
          @include xy-grid-layout(2, '.tile',$gutters:25);
        }
        @include breakpoint(large) {
          @include xy-grid-layout(2, '.tile',$gutters:50);
        }
      }
      &.palos {
        @include breakpoint(large) {        
          @include xy-cell(8);
        }
        padding-bottom: 2rem;
        .grid {
          @include xy-grid();

          @include breakpoint(large) {
            @include xy-grid-layout(2, '.tile',$gutters:40);
            @include xy-gutters(40,$negative:true)
          }
        }
      }
      .tile {
        margin-top: 1.5rem;
        .tags {
          margin-top: .5rem;
          margin-bottom: 0;
        }
        .comment-count {
          font-family: $header-font-family;
          color: $mahogany;
          span.far {
            font-size: .66rem;
            margin-right: .25rem;
          }  
        }
      }  
    }
  }

  .category-heading {
    @include xy-cell();
    margin-top: .5rem;
    margin-bottom: 1rem;
    margin-left: 0;
    font-family: $header-sc-font-family;
    text-transform: capitalize;
    border-bottom: 1px solid $mahogany; 
    @include breakpoint(small only) {
      width: 100%;
    }
  }
  .article-listing {
    @include xy-grid();
    @include xy-gutters(0);
    font-size: .8889rem;
    margin-bottom: 2rem;          
    h2, h3.tab {
      font-family: $header-font-family;
      font-size: 1.5rem;
      margin-bottom: .25rem;
      @include breakpoint(large) {
        margin: 0;
        line-height: 1.25;
      }
    }

    @include breakpoint(large) {
      @include xy-grid(vertical);
      height: 250px;
    }
    h2, h3, ul, p, div {
      @include xy-cell($gutters:0);
      @include breakpoint(large) {
        @include xy-cell(6);
        padding-left: .5rem;           
      }
    }
    .hero {        
      @include breakpoint(large) {
        @include xy-cell(6);
        @include flex-order(-1);
        margin-left: 0;
        height: 100%; /* keeps image in own column */
      } 
    }
    p {
      margin-bottom: .5rem;
      font-size: 17px;
      @include breakpoint(large) {
        font-size: 16px;
      }
    }
    .comment-count {
      font-family: $header-font-family;
      color: $mahogany;
      span.far {
        font-size: .66rem;
        margin-right: .25rem;
      }  
    }
  }  
  
// 6. Resource Page Styles
// -----------------------
  article.resource-page {
    @include xy-grid();
    header {
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(11);
        @include xy-cell-offset(1);
        @include flex-order(-10);
      }
      .tags {
        margin-bottom: 1rem;
      }
    }
    .video {
      @include xy-cell();
      @include xy-gutters(10,$negative:true);
      width: 100vw;
      @include breakpoint(large) {
        @include xy-cell(7);
        @include flex-order(-8);
      }
    }  
    .hero {
      @include xy-cell();
      @include xy-gutters(10,$negative:true);
      width: 100vw;
      img {
        width: 100%;
      }
      @include breakpoint(large) {
        @include xy-cell(7);
        @include flex-order(-8);
      }
      figcaption {
        text-align: right;
        margin-top: -.3rem;
        padding-right: 10px;
      }
    }
    
    section.lede {
      @include xy-cell();
      font-weight: 600;
      margin-bottom: 1rem;
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
        margin-bottom: 0;
      }
      @include breakpoint(large) {
        @include xy-cell(7);
        @include xy-cell-offset(1);
        margin-bottom: 0;
      }
    }
    section.ad-block {
      @include xy-cell();
      margin-bottom: 1.5rem;
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(4);
        @include flex-order(-7);
        text-align: center;
        img {
          display: inline-block;
        }
      }
    }
    section.article-wrapper {
      @include flex;
      .social-comment-count {
        display: none;
        @include breakpoint(large) {
          @include xy-cell(1);
          display: block;
          margin-top: .75rem;
          margin-bottom: 12rem;
        } 
        .tags {
          direction: rtl;
          margin-top: 2rem;
          margin-right: 1rem;
          li {
            display: block;
            margin-bottom: .5rem;
          }
        }
      }
      .article-body {
        @include xy-cell();
        margin-bottom: 1rem;
        @include breakpoint(medium) {
          @include xy-cell($gutters: 25);
        }
        @include breakpoint(large) {
          @include xy-cell(7);
          padding-right: 3rem;
        }
        h2, h3 {
          padding-top: 1rem;
          clear: left;
        }
        h4 {
          font-family: $header-sc-font-family;
          font-weight: 500;
        }
        figure {
          margin: 2rem 0 2rem;
        }
        img {
          width: 100%;
          margin-bottom: 1rem;
          @include breakpoint(medium) {
            width: 50%;
            float: left;
            padding-right: 1rem;
            padding-top: .5rem;
          }
          &.cleared {
            float: none;
            margin: 0 auto;
            width: initial;
            max-width: 100%;
          }
        }
        p a:link, p a:visited {
          color: $cedar;
          &.button {
            color: $bone;
          }
        }
        .album-row {
          @include xy-grid();
          @include xy-grid-layout(1, '.album', $gutters: 50, $gutter-position: top right bottom left);
          @include breakpoint(medium) {
            @include xy-grid-layout(3, '.album');
          }
          img {
            width: 100%;
          }
        }
        .button {
          margin-left: 1rem;
          margin-top: 1rem;
          color: $bone;
        }
        .button.tab {
          //margin-top: 1.5rem;
          //float:right;
        }
        .fa {
          padding-right: .75rem;
        }
        .tab-details {
          @include xy-grid();
          @include flex-align(justify, middle);
          .skill-level {
            @include xy-cell(6, $gutters:0);
            font-size: 22px;
            font-family: $header-sc-font-family;
            line-height: 1.2;
          }
          .button {
            @include xy-cell(6);
            max-width: 200px;
            margin-top: .5rem;
          }
        }
        span.count {
          display: inline-block;
          background-color: $bone;
          padding: 1rem;
          margin: .5rem;
          border: solid $cypress 2px;
          border-radius: 5px;
          letter-spacing: .125rem;
          color: $mahogany;
          @include breakpoint(large) {
            letter-spacing: .25rem;
          }
          strong {
            color: scale-color($cedar, $lightness: 25%);
          }
        }
      }
      .ad-block {
        display: none;
        @include breakpoint(large) {
          @include xy-cell(4);
          @include flex-order(1);
          display: block;
          margin-top: .75rem;
          margin-bottom: 12rem;
        } 
      }
      #social-comment-rail, #ad-rail {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        &.scroll-view {
          opacity: 1;
        }
      }
    }
  }

// 7. Metronome Page Styles
// ------------------------

  .metronome-wrap {
      @include xy-cell();
      @include breakpoint(small only) {
        @include xy-gutters(10,$negative:true);
        width: 100vw;
      }
      @include breakpoint(medium) {
        max-width: 650px;
      }
      @include breakpoint(large) {
        @include xy-cell(7);
        @include flex-order(-8);
      } 
    .button {
      border: 1px solid $cypress;
      background-color: $bone;
    }
  }
  .metronome-social {
    @include breakpoint(small only) {
      padding-top: 1rem;
    }
  }
  .metronome-lede {
    margin-top: 1rem;
  }
  .metronome-description {
    @include xy-cell();
    @include breakpoint(medium) {
      @include xy-cell($gutters:25);
    }
    @include breakpoint(large) {
      @include xy-cell(7);
      @include xy-cell-offset(1);
    }

  }

// 8. Tag Index, 404 & Generic Page Styles
// ---------------------------------------
  .tag-index, .error, .about, .contact {
    @include xy-grid();
    margin-top: .5rem;
    @include breakpoint(large) {
      margin-top: 1rem;
    }
    header, .tag-categories, .error-message {
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell(8);
        @include xy-cell-offset(1);
      }  
      @include breakpoint(large) {
        @include xy-cell(8);
        @include xy-cell-offset(1);
      }
    }
    .about-content, .mail-form {
      @include xy-cell();
      margin-bottom: 4rem;
      @include breakpoint(medium) {
        @include xy-cell(8);
        @include xy-cell-offset(1);
      }      
      @include breakpoint(large) {
        @include xy-cell(7);
        @include xy-cell-offset(1);
      }
    }
    ul.tags {
      display: none;
    }
    .error-message {
      h2 {
        border-bottom: solid $mahogany 1px;
        margin-top: 1rem;
      }
      img {
        @include breakpoint(large) {
          width: 100%;
          margin: 1rem;
        }
      }
      div {
        column-count: 2;
        @include breakpoint(large) {
          column-count: 3;
        }
        padding-bottom: 4rem;
        ul {
          font-family: $header-font-family;
          font-size: 20px;
          list-style: none;
          margin-left: 0;
        }
        .count {
          font-size: 16px;
          color: $mahogany;
        }
      }
    }
    #contact-form {
      @include xy-grid-layout(1, input);
      input {
        @include xy-cell($gutters: 0);
        @include breakpoint(medium) {
          @include xy-cell(8, $gutters: 0);
        }
        @include breakpoint(large) {
          @include xy-cell(6, $gutters: 0);
        }
      }
    }
  }

// 9. Comment Block Styles
// -----------------------
  #comments {
    @include xy-grid();
    width: 100%; /*don't know why*/
    padding-bottom: 1rem;
    h2 {
      display: none;
      @include breakpoint(large) {
        display: block;
        @include xy-cell($gutters:50);
      }
      border-top: solid 2px $mahogany;
    }
    .ad-block {
      margin-bottom: 2rem;
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(4, $gutters:50);
        @include flex-order(1);
      }
    }
    #disqus_thread {
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      @include breakpoint(large) {
        @include xy-cell(8, $gutters:50);
      }
    }
  }

// 10. Related Content Styles
// --------------------------
  aside.related-content {
    background: $bone;
    border-top: $cypress 3px solid;
    margin: 1rem 0 0;
    padding: .5rem 0 1.5rem;
    h1 {
      @include xy-cell();
      @include breakpoint(medium) {
        @include xy-cell($gutters:25);
      }
      font-size: 1.66rem;
      margin-bottom: 0;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: .25rem;
      @include breakpoint(medium only){
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    @include breakpoint(large) {
      h1 {
        font-size: 2rem;
        @include xy-cell($gutters:50);
      }
      h2 {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    a {
      //color: $cedar;
    }
    article.related-articles, article.recent-comments {
      @include xy-grid-container();
      .article-list {
        @include xy-grid();
        @include breakpoint(large) {
          @include xy-grid-layout(3, '.tile', $gutters:50);
        }
      }
      padding-bottom: 2rem;
      .tile {
        @include xy-cell();
        @include breakpoint(medium only) {
          @include xy-grid(vertical);
          @include xy-gutters(0);
          height: 275px;
          margin-top: 2rem;
          &:last-child {
            margin-bottom: 1rem;
          }
        }
        h2, ul, p, {
          @include breakpoint(medium only) {
            @include xy-cell(6, $gutters:25);
          }
        }
        .hero {        
          @include breakpoint(medium only) {
            @include xy-cell(6, $gutters:25);
            @include flex-order(-1);
            height: 100%; /* keeps image in own column */
          } 
        }
      }
      ul.tags {
        margin-top: .5rem;
        margin-bottom: .125rem;
        color: $mahogany; 
        line-height: 1.25rem;
      }
      p {
        margin-bottom: .5rem;
        font-size: 17px;
      }
      .tags li:not(:last-child):after {
        content: ",";
      }
    }

  // recent comments home page list ---
    ul.dsq-widget-list {
      margin: 0;
      padding-right: .5rem;
      @include xy-grid();
      @include breakpoint(medium) {
        @include xy-grid-layout(2, '.dsq-widget-item', $gutters:25);
      }
      @include breakpoint(large) {
        @include xy-grid-layout(3, '.dsq-widget-item', $gutters:50);
      }
    }
    li.dsq-widget-item {
      @include xy-cell();
      margin-top: 1.5rem;
      font-size: 16px;
      font-weight: 600;
      @include breakpoint(large) {
        margin-top: 2rem;
      }
      img.dsq-widget-avatar {
        width: 75px;
        height: 75px;
        margin-top: .5rem;
        margin-right: .5rem;
      }
      .dsq-widget-comment p {
        font-weight: 300; 
      }
      p.dsq-widget-meta {
        font-family: $header-font-family; 
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        padding-top: .25rem;
      }
      p.dsq-widget-meta a:last-child:after {
        //content: " - view comment";
      }
    }
  }

// 11. Footer Styles
// -----------------
  footer {
    background: $mahogany;
    border-top: $cedar 3px solid;
    padding-top: 1rem;
    color: $bone;
    font-family: $header-sc-font-family;
    h1 {
      font-size: 1.5rem;
      color: $bone;
      border-bottom: $bone 1px solid;
      @include breakpoint(large) {
        font-size: 1.5rem;
      }
    }
    ul {
      margin-left: 0;
    }
    li {
        font-weight: 300;
        font-size: 1.2rem;
        list-style: none;
        line-height: 2;
    }
    a:link, a:visited, a:active  {
      color: $bone;
      &:hover {
        color: $cypress;
      }
    }
    .fab, .fas {
      margin-right: 1rem;
    }
    .site-footer {
      @include xy-grid-container();
    }
    nav {
      @include xy-grid();
      @include breakpoint(medium) {
        @include xy-grid-layout(3, 'section', $gutters:25);
      }
      @include breakpoint(large) {
        @include xy-grid-layout(3, 'section', $gutters:50);
      }
    }
    section {
      @include xy-cell();
    }  
    .copyright {
      @include xy-cell();
      padding-top: .5rem;
      font-weight: 300;
      @include breakpoint(large) {
        text-align: center;
        padding-right: 2rem;
      }
    }
  }